@keyframes moveText {
    100% {
      transform: translateX(50%);
    }
    30% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }

  }
  
  .moving-text {
    animation: moveText 10s ease-in-out infinite;
  }